<template>
  <div class="wrapper">
    <h2
      class="GalleryTitle wow animate__animated animate__fadeInUp"
      data-wow-duration="1s"
    >
      Gallery
  </h2>
    <div style="height: 40px"></div>
    <div class="Glalery">
      <img
        :class="[
          isSpecialIndex(index) ? 'GalImg' : 'GalImg2',
          { mL: (index + 1) % 2 === 0 },
          index % 2 === 0
            ? 'wow animate__animated animate__fadeInLeft'
            : 'wow animate__animated animate__fadeInRight',
        ]"
        v-for="(item, index) in list"
        :key="index"
        :src="item.src"
      />
      <!-- <img :src="list[0].src"> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { src: require("../../assets/info/gallery/connel.png") },
        { src: "https://img.js.design/assets/img/6713a4433beaeb61883843f3.png#a434053df76b0d3743207b286af07c13" },


        { src: require("../../assets/info/gallery/huli-awsl-untitled1.jpg") },
        { src: require("../../assets/info/gallery/grass.png") },
      ],
    };
  },
  methods: {
    getClass(index) {
      index;
    },
    isSpecialIndex(index) {
      // index 从 0 开始，需要 +1
      index = index + 1;

      let position = (index - 1) % 4; // 确定在循环中的位置

      // 如果是循环中的第一个或第三个位置，并且索引匹配模式
      return position === 0 || position === 3;
    },
  },
};
</script>

<style scoped>
.GalleryTitle {
  font-family: sans-serifh;
  margin-top: 100px;
  width: 264px;
  font-size: 48px;
  font-weight: 400;
  color: white;
  border-bottom: 6px solid #e8ce81;
}
.Gallery {
  margin-top: 10px;
}
.GalImg {
  margin-top: 15px;
  width: 382px;
  height: 447px;
}
.GalImg2 {
  margin-top: 15px;

  width: 793px;
  height: 447px;
}
.mL {
  margin-left: 15px;
}
</style>
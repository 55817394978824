import Vue from 'vue'
import router from './router'
import './glbstyle.css'
import wow from 'wowjs'
import 'animate.css'
import App from './App.vue'


Vue.prototype.$wow = wow

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
router.beforeEach((to, from, next) => {//beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {//判断是否有标题
    document.title = to.meta.title
  }else{
    document.title = "Huli-2024"
  }
  next()//执行进入路由，如果不写就不会进入目标页
})
// Huli()

// function Huli(){
//   console.log("Hi there!")
// }
<template>
  <div class="wrapper">
    <h2
      class="OngoTitle wow animate__animated animate__fadeInUp"
      data-wow-duration="1s"
    >
      Ongoing
  </h2>
    <div class="Ongo">
      <div
        class="appdev wow animate__animated animate__bounceInLeft"
        data-wow-duration="1s"
      >
        App Development
      </div>
      <ul>
        <li
          v-for="(item, index) in ongoList"
          :key="index"
          :class="[
            'progress-' + item.progress,
            index % 2 === 0
              ? 'wow animate__animated animate__backInLeft'
              : 'wow animate__animated animate__backInRight',
          ]"
          data-wow-delay="0.3s"
          data-wow-offset="100"
        >
          <div class="ongoname">{{ item.name }}</div>
          <div class="ongoprog">{{ item.progress + "%" }}</div>
          <div class="progress-bar">
            <div
              :class="'progress-bar-inner'"
              :style="{ width: item.progress + '%' }"
            ></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ongoList: [
        { name: "BVE5 Motor Assistant", progress: 35 },
      ],
    };
  },
  mounted() {
    new this.$wow.WOW().init();
  },
};
</script>

<style lang="less" scoped>
.OngoTitle {
  margin-top: 100px;
  font-family: sans-serifh;
  width: 264px;
  font-size: 48px;
  font-weight: 400;
  color: white;
  border-bottom: 6px solid #e8ce81;
}
.Ongo {
  margin-top: 70px;
  margin-bottom: 100px;
  .appdev {
    margin-bottom: 40px;
    position: relative;
    left: 50px;
    height: 40px;
    font-family: Anonymous-Pro;
    line-height: 40px;
    font-size: 36px;
    font-weight: 700;
    color: #fff;
  }
  .appdev::before {
    position: absolute;
    display: inline-block;
    top: 6px;
    left: -50px;
    content: "";
    width: 36px;
    height: 36px;
    background-size: 36px 36px;
    background-image: url(../../assets/info/app.png);
  }
  ul {
    width: 1200px;
    li {
      margin-bottom: 80px;
      height: 30px;
      font-family: "Anonymous-Pro";
      font-size: 30px;
      font-weight: 400;
      line-height: 30px;
      color: #fff;
      position: relative;
      .ongoname {
        float: left;
      }
      .ongoprog {
        float: right;
      }
      .progress-bar {
        position: relative;
        top: 44px;
        height: 14px;
        width: 1200px;
        background-color: #fff4e3;
        border-radius: 7px;
      }
      .progress-bar-inner {
        height: 14px;
        background: #e7c48a;
        position: absolute;
        border-radius: 7px;
      }
    }
  }
}
</style>
